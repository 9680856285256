@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.activeSidebar {
	color: #fff;
	background: linear-gradient(111.88deg, #3a5ef0 44.35%, #0f2daa 100%);
	border-radius: 4px;
}

.inActiveSidebar {
	opacity: 0.5;
	color: #fff;
}

.cms-glassmorphism {
	background: rgba(255, 255, 255, 0.6);
	border-radius: 16px;
	box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}

.chakra-button__icon {
	margin-left: auto !important ;
}
